var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "ty-page-top" }, [
        _c(
          "div",
          {
            staticClass: "pb36",
            staticStyle: {
              "text-align": "left",
              "line-height": "48px",
              display: "flex",
              "justify-content": "space-between"
            }
          },
          [
            _c(
              "span",
              [
                _c(
                  "el-button",
                  {
                    staticClass: "hq-button",
                    on: {
                      click: function($event) {
                        return _vm.cancel()
                      }
                    }
                  },
                  [_vm._v("返回")]
                )
              ],
              1
            ),
            _vm.canAfterSale
              ? _c(
                  "span",
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          disabled: _vm.refund,
                          effect: "light",
                          content: _vm.refundMsg,
                          placement: "bottom-end"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "inline" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "hq-button",
                                attrs: {
                                  type: "primary",
                                  disabled: !_vm.refund
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.goDropOutPage()
                                  }
                                }
                              },
                              [_vm._v("退费")]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          disabled: _vm.turnClass,
                          effect: "light",
                          content: _vm.turnClassMsg,
                          placement: "bottom-end"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "inline" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "hq-button",
                                attrs: {
                                  type: "primary",
                                  disabled: !_vm.turnClass,
                                  plain: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.goTransferPage()
                                  }
                                }
                              },
                              [_vm._v("转班")]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          disabled: _vm.upClass,
                          effect: "light",
                          content: _vm.upClassMsg,
                          placement: "bottom-end"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "inline" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "hq-button",
                                attrs: { disabled: !_vm.upClass },
                                on: {
                                  click: function($event) {
                                    return _vm.goUpgradePage()
                                  }
                                }
                              },
                              [_vm._v("升班")]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          disabled: !_vm.cancelOrClose.cancelMsg,
                          effect: "light",
                          content: _vm.cancelOrClose.cancelMsg,
                          placement: "bottom-end"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "inline" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "hq-button",
                                attrs: {
                                  disabled: !_vm.cancelOrClose.cancel,
                                  type: "primary"
                                },
                                on: { click: _vm.toRemoveOperation }
                              },
                              [_vm._v("取消订单")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      ]),
      _c("div", { staticClass: "ty-page-elform" }, [
        _c("div", { staticClass: "ty-page-elform-section pb36" }, [
          _c(
            "div",
            {
              staticClass: "ty-info_title",
              staticStyle: { margin: "18px 0 12px 0" }
            },
            [_vm._v("订单概况")]
          ),
          _c(
            "div",
            {
              staticClass: "ty-w1000-content",
              staticStyle: { background: "#f5f7fa", padding: "12px 0 0 20px" }
            },
            [
              _c(
                "el-row",
                { staticClass: "order-detail" },
                [
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c("p", [_vm._v("订单号")]),
                    _c("p", { staticStyle: { color: "#333" } }, [
                      _vm._v(_vm._s(this.orderNo))
                    ])
                  ]),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "padding-right": "18px" },
                      attrs: { span: 7 }
                    },
                    [
                      _c("p", [_vm._v("报名商品")]),
                      _c("p", { staticStyle: { color: "#333" } }, [
                        _vm._v(
                          _vm._s(
                            this.toGoodsName + "(" + this.toCommodityName + ")"
                          )
                        )
                      ])
                    ]
                  ),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("p", [_vm._v("待收金额")]),
                    _c("p", { staticStyle: { color: "#181818" } }, [
                      _vm._v(_vm._s(this.allPayText))
                    ])
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("p", [_vm._v("订单状态")]),
                    _c("p", [
                      _c("span", { staticStyle: { color: "#337AFF" } }, [
                        _vm._v(_vm._s(this.orderStatus))
                      ]),
                      _vm.isLock ? _c("span", [_vm._v("（已锁单）")]) : _vm._e()
                    ])
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c("p", [_vm._v("报名类型")]),
                    _c("p", { staticStyle: { color: "#333" } }, [
                      _vm._v(_vm._s(this.orderTypeText))
                    ])
                  ]),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c("p", [_vm._v("报名时间")]),
                    _c("p", { staticStyle: { color: "#333" } }, [
                      _vm._v(_vm._s(this.orderCreateTime))
                    ])
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("p", [_vm._v("原订单号")]),
                    _c("p", { staticStyle: { color: "#333" } }, [
                      _vm._v(_vm._s(this.oldOrderNo || "--"))
                    ])
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("p", [_vm._v("原报名商品")]),
                    _c("p", { staticStyle: { color: "#333" } }, [
                      _vm._v(_vm._s(this.oldGoodsName || "--"))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "Mark", staticStyle: { width: "100%" } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    stripe: "",
                    size: "mini",
                    "element-loading-text": "拼命加载中",
                    "header-cell-style": { background: "#e5f2ff" },
                    height: 240,
                    data: _vm.orderData
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "orderKey",
                      label: "订单流程",
                      "min-width": "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "orderStatus",
                      label: "状态",
                      "min-width": "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "orderOperator",
                      label: "操作",
                      "min-width": "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm.ios !== 1 &&
                            scope.row.orderType === "1" &&
                            (scope.row.orderStatus === "支付完成" ||
                              _vm.orderStatus === "订单关闭")
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "btn",
                                        attrs: {
                                          type: "primary",
                                          plain: "",
                                          size: "mini"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.paymentDetail()
                                          }
                                        }
                                      },
                                      [_vm._v(" 收款明细 ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.ios !== 1 &&
                            scope.row.orderType === "1" &&
                            _vm.orderStatus != "订单关闭" &&
                            _vm.paymentData[0].collectionAmount
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "btn",
                                        attrs: {
                                          type: "primary",
                                          size: "mini"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.userPay()
                                          }
                                        }
                                      },
                                      [_vm._v(" 学员收款 ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.ios !== 1 &&
                            scope.row.orderType === "1" &&
                            _vm.orderStatus != "订单关闭" &&
                            _vm.whetherHaveVoucher
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "btn",
                                        attrs: {
                                          type: "primary",
                                          size: "mini"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.goCollection(
                                              _vm.allCollectionInformation[0]
                                                .processInstanceId
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 收款确认 ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            scope.row.orderType === "2" &&
                            scope.row.orderStatus != "线下签订"
                              ? [
                                  scope.row.orderType === "2" &&
                                  (scope.row.orderStatus === "待审核" ||
                                    scope.row.orderStatus === "通过" ||
                                    _vm.orderStatus === "订单关闭" ||
                                    scope.row.orderStatus === "驳回")
                                    ? _c(
                                        "span",
                                        [
                                          _vm.btnChangeHiden
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticClass: "btn",
                                                  attrs: {
                                                    type:
                                                      scope.row.orderStatus ===
                                                        "待审核" ||
                                                      scope.row.orderStatus ===
                                                        "通过" ||
                                                      scope.row.orderStatus ===
                                                        "驳回"
                                                        ? "primary"
                                                        : "text",
                                                    plain:
                                                      scope.row.orderStatus ===
                                                        "待审核" ||
                                                      scope.row.orderStatus ===
                                                        "通过" ||
                                                      scope.row.orderStatus ===
                                                        "驳回",
                                                    disabled:
                                                      _vm.btnChangeEnable,
                                                    size: "mini"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.signDetail()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row
                                                          .orderStatus ===
                                                          "待审核" ||
                                                          scope.row
                                                            .orderStatus ===
                                                            "通过" ||
                                                          scope.row
                                                            .orderStatus ===
                                                            "驳回"
                                                          ? "查看协议"
                                                          : "--"
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  scope.row.orderType === "2" &&
                                  scope.row.orderStatus === "待审核" &&
                                  _vm.isSchoolEq
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "btn",
                                              attrs: {
                                                type: "primary",
                                                size: "mini"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.showPassDialog()
                                                }
                                              }
                                            },
                                            [_vm._v(" 通过 ")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.orderStatus != "订单关闭" &&
                                  (scope.row.orderStatus === "待审核" ||
                                    scope.row.orderStatus === "通过") &&
                                  _vm.isSchoolEq
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "btn",
                                              attrs: {
                                                type: "danger",
                                                disabled: _vm.rejectEnable,
                                                size: "mini"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.reject()
                                                }
                                              }
                                            },
                                            [_vm._v(" 驳回 ")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  scope.row.orderType === "2" &&
                                  _vm.orderStatus != "订单关闭" &&
                                  (scope.row.orderStatus === "待签约" ||
                                    scope.row.orderStatus == "驳回")
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "btn",
                                              attrs: {
                                                type: "primary",
                                                size: "mini"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.userSign()
                                                }
                                              }
                                            },
                                            [_vm._v(" 学员签约 ")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  scope.row.orderStatus == "协议异常"
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "btn",
                                              attrs: { type: "primary" },
                                              on: { click: _vm.signOnceClick }
                                            },
                                            [_vm._v("重推协议")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e(),
                            scope.row.orderType === "3"
                              ? _c("span", [_vm._v(" -- ")])
                              : _vm._e(),
                            scope.row.orderType === "4"
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "btn",
                                        attrs: {
                                          type: "primary",
                                          plain: "",
                                          size: "mini"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.goodsDetail()
                                          }
                                        }
                                      },
                                      [_vm._v(" 商品详情 ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            scope.row.orderType === "5" &&
                            scope.row.orderStatus !== undefined &&
                            scope.row.orderStatus.indexOf("转校") == -1
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "btn",
                                        attrs: {
                                          type: "primary",
                                          plain: "",
                                          size: "mini"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.afterSaleView(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v(" 售后详情 ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "paymentStatus",
                      label: "时间",
                      width: "170",
                      "label-class-name": "ty-ml8"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "line" }),
        _c("div", { staticClass: "ty-page-elform-section pb36" }, [
          _c("div", { staticClass: "ty-info_title" }, [_vm._v(" 报名信息 ")]),
          _c("div", [
            _c("div", { staticClass: "ty-title2" }, [_vm._v("学员信息")]),
            _c("div", { staticClass: "Mark", staticStyle: { border: "0px" } }, [
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: {
                        border: "1px solid #e1e4eb",
                        width: "100%"
                      },
                      attrs: {
                        data: _vm.userData,
                        border: "",
                        stripe: "",
                        size: "mini",
                        "element-loading-text": "拼命加载中",
                        "header-cell-style": { background: "#e5f2ff" }
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "userId",
                          label: "学员ID",
                          "min-width": "100"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "mobile",
                          label: "学员账号",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: "学员姓名",
                          "min-width": "120"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    _vm.editStudentInfo
                                      ? _c("v-text-field", {
                                          staticStyle: { padding: "6px 0" },
                                          attrs: {
                                            label: "学员姓名",
                                            "hide-details": "auto",
                                            outlined: "",
                                            dense: true,
                                            height: 32
                                          },
                                          model: {
                                            value: scope.row.name,
                                            callback: function($$v) {
                                              _vm.$set(scope.row, "name", $$v)
                                            },
                                            expression: "scope.row.name"
                                          }
                                        })
                                      : _c("span", [
                                          _vm._v(_vm._s(scope.row.name))
                                        ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          formatter: _vm.isEmptyFun,
                          prop: "idCard",
                          label: "身份证号",
                          "min-width": "170",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    _vm.editStudentInfo
                                      ? _c("v-text-field", {
                                          staticStyle: { padding: "6px 0" },
                                          attrs: {
                                            label: "身份证号",
                                            "hide-details": "auto",
                                            outlined: "",
                                            dense: true,
                                            height: 32
                                          },
                                          model: {
                                            value: scope.row.idCard,
                                            callback: function($$v) {
                                              _vm.$set(scope.row, "idCard", $$v)
                                            },
                                            expression: "scope.row.idCard"
                                          }
                                        })
                                      : _c("span", [
                                          _vm._v(_vm._s(scope.row.idCard))
                                        ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "学员学历",
                          "min-width": "120",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm.editStudentInfo
                                  ? _c("dict-select", {
                                      attrs: { code: "EDUCATION_TYPE" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(props) {
                                              return _c("v-select", {
                                                staticStyle: {
                                                  padding: "6px 0"
                                                },
                                                attrs: {
                                                  items: props.dictData,
                                                  "menu-props": {
                                                    bottom: true,
                                                    offsetY: true
                                                  },
                                                  label: "学员学历",
                                                  "item-text": "label",
                                                  "item-value": "value",
                                                  outlined: "",
                                                  dense: true,
                                                  height: 32,
                                                  clearable: true,
                                                  "clear-icon": "$clear",
                                                  "hide-details": "auto"
                                                },
                                                model: {
                                                  value: scope.row.education,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "education",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.education"
                                                }
                                              })
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.educationName))
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          formatter: _vm.isEmptyFun,
                          prop: "emergencyContact",
                          label: "紧急联系人",
                          "min-width": "170",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    _vm.editStudentInfo
                                      ? _c("v-text-field", {
                                          staticStyle: { padding: "6px 0" },
                                          attrs: {
                                            label: "紧急联系人",
                                            "hide-details": "auto",
                                            outlined: "",
                                            dense: true,
                                            height: 32
                                          },
                                          model: {
                                            value: scope.row.emergencyContact,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "emergencyContact",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.emergencyContact"
                                          }
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.emergencyContact)
                                          )
                                        ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          formatter: _vm.isEmptyFun,
                          prop: "emergencyContactPhone",
                          label: "紧急联系人电话",
                          "min-width": "170",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    _vm.editStudentInfo
                                      ? _c("v-text-field", {
                                          staticStyle: { padding: "6px 0" },
                                          attrs: {
                                            label: "紧急联系人",
                                            "hide-details": "auto",
                                            outlined: "",
                                            dense: true,
                                            height: 32
                                          },
                                          model: {
                                            value:
                                              scope.row.emergencyContactPhone,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "emergencyContactPhone",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.emergencyContactPhone"
                                          }
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.emergencyContactPhone
                                            )
                                          )
                                        ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          width: "205",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm.isSchoolEq &&
                                !(_vm.orderData[1].orderStatus == "通过")
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.editEndSaveClick(
                                              scope.row
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.editStudentInfo
                                              ? "保存"
                                              : "编辑"
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.editStudentInfo
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "small" },
                                        on: {
                                          click: function($event) {
                                            _vm.editStudentInfo = false
                                          }
                                        }
                                      },
                                      [_vm._v("取消")]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _c("div", [
            _c("div", { staticClass: "ty-title2" }, [_vm._v("成交信息")]),
            _c("div", { staticClass: "Mark" }, [
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "tb-edit-input",
                      staticStyle: { width: "100%" },
                      attrs: {
                        border: "",
                        stripe: "",
                        size: "mini",
                        "element-loading-text": "拼命加载中",
                        "header-cell-style": { background: "#e5f2ff" },
                        data: _vm.userData
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          formatter: _vm.isEmptyFun,
                          prop: "customerCode",
                          label: "客户编号",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "ncCustomerUserId",
                          label: "商机编号",
                          "min-width": "190"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "phoneNumber",
                          label: "手机",
                          "min-width": "138",
                          "class-name": "tb-input"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return _c("div", {}, [
                                _vm._v(
                                  " " + _vm._s(scope.row.phoneNumber) + " "
                                )
                              ])
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          formatter: _vm.isEmptyFun,
                          prop: "dealSchoolName",
                          label: "成交校区",
                          "min-width": "150"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return _c("div", {}, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.subscribeSchoolName === ""
                                        ? "--"
                                        : scope.row.subscribeSchoolName
                                    )
                                  )
                                ])
                              ])
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          formatter: _vm.isEmptyFun,
                          prop: "campusSchoolName",
                          label: "上课校区",
                          "min-width": "150"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return _c("div", {}, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.signSchoolName === ""
                                        ? "--"
                                        : scope.row.signSchoolName
                                    )
                                  )
                                ])
                              ])
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          formatter: _vm.isEmptyFun,
                          prop: "teacherRecruitName",
                          label: "招生老师",
                          "min-width": "130"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return _c("div", {}, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.teacherRecruitName === ""
                                        ? "--"
                                        : scope.row.teacherRecruitName
                                    )
                                  )
                                ])
                              ])
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          formatter: _vm.isEmptyFun,
                          prop: "teacherSalesName",
                          label: "销售老师",
                          "min-width": "130"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return _c("div", {}, [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.teacherSalesName || "--")
                                  )
                                ])
                              ])
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "isRecommand",
                          label: "转介绍",
                          width: "80",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.isRecommand == 1 ? "是" : "否"
                                    )
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "transIntroduceType",
                          label: "转介绍类型",
                          width: "200",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.transIntroduceType == 0 ||
                                        scope.row.transIntroduceType == 1
                                        ? _vm.recommandTypeMap[
                                            scope.row.transIntroduceType
                                          ]
                                        : "--"
                                    )
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "teacherRecommandName",
                          label: "转介绍老师",
                          width: "200",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.teacherRecommandName || "--"
                                    )
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "assistDealCrmUserId",
                          label: "协助成交人员",
                          width: "200",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm.edit && scope.row.isRecommand == 0
                                  ? _c("v-autocomplete", {
                                      staticStyle: { padding: "6px 0" },
                                      attrs: {
                                        items: _vm.assistDealList,
                                        "menu-props": {
                                          bottom: true,
                                          offsetY: true
                                        },
                                        label: "协助成交人员",
                                        outlined: "",
                                        "item-text": "assistDealUserName",
                                        "item-value": "assistDealCrmUserId",
                                        dense: true,
                                        height: 32,
                                        clearable: true,
                                        "clear-icon": "$clear",
                                        "hide-details": "auto",
                                        "no-data-text": "暂无数据"
                                      },
                                      model: {
                                        value: scope.row.assistDealCrmUserId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "assistDealCrmUserId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "scope.row.assistDealCrmUserId"
                                      }
                                    })
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.assistDealUserName || "--"
                                        )
                                      )
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "teacherLecturerName",
                          label: "讲师",
                          width: "200",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.teacherLecturerName || "--"
                                    )
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "openClassType",
                          label: "公开课类型",
                          width: "200",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm.edit
                                  ? _c("v-autocomplete", {
                                      staticStyle: { padding: "6px 0" },
                                      attrs: {
                                        items: [
                                          {
                                            id: "普通小型公开课",
                                            name: "普通小型公开课"
                                          },
                                          {
                                            id: "小型校内宣讲",
                                            name: "小型校内宣讲"
                                          },
                                          {
                                            id: "大型校内宣讲",
                                            name: "大型校内宣讲"
                                          },
                                          {
                                            id: "特训营正课试听",
                                            name: "特训营正课试听"
                                          }
                                        ],
                                        "menu-props": {
                                          bottom: true,
                                          offsetY: true
                                        },
                                        label: "公开课类型",
                                        outlined: "",
                                        "item-text": "name",
                                        "item-value": "id",
                                        dense: true,
                                        height: 32,
                                        clearable: true,
                                        "clear-icon": "$clear",
                                        "hide-details": "auto",
                                        "no-data-text": "暂无数据"
                                      },
                                      model: {
                                        value: scope.row.openClassType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "openClassType",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.openClassType"
                                      }
                                    })
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(scope.row.openClassType || "--")
                                      )
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "lecturerAssigned",
                          label: "是否外派",
                          width: "200",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm.edit
                                  ? _c("v-autocomplete", {
                                      staticStyle: { padding: "6px 0" },
                                      attrs: {
                                        items: [
                                          { id: true, name: "是" },
                                          { id: false, name: "否" }
                                        ],
                                        "menu-props": {
                                          bottom: true,
                                          offsetY: true
                                        },
                                        label: "是否外派",
                                        outlined: "",
                                        "item-text": "name",
                                        "item-value": "id",
                                        dense: true,
                                        height: 32,
                                        clearable: true,
                                        "clear-icon": "$clear",
                                        "hide-details": "auto",
                                        "no-data-text": "暂无数据"
                                      },
                                      model: {
                                        value: scope.row.lecturerAssigned,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "lecturerAssigned",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.lecturerAssigned"
                                      }
                                    })
                                  : _c("div", [
                                      scope.row.lecturerAssigned == true
                                        ? _c("span", [_vm._v("是")])
                                        : scope.row.lecturerAssigned == null
                                        ? _c("span")
                                        : _c("span", [_vm._v("否")])
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "economicFlowPartner",
                          label: "流量经纪人",
                          width: "200",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.economicFlowPartner || "--"
                                    )
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "flowPartner",
                          label: "流量合伙人",
                          width: "200",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", [
                                  _vm._v(_vm._s(scope.row.flowPartner || "--"))
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "isSixFour",
                          label: "是否46分成",
                          width: "200",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm.edit &&
                                (_vm.saleSourceName == "智慧校区web" ||
                                  _vm.saleSourceName == "智慧校区h5")
                                  ? _c("v-autocomplete", {
                                      staticStyle: { padding: "6px 0" },
                                      attrs: {
                                        items: [
                                          { id: 1, name: "是" },
                                          { id: 0, name: "否" }
                                        ],
                                        "menu-props": {
                                          bottom: true,
                                          offsetY: true
                                        },
                                        label: "是否46分成",
                                        outlined: "",
                                        "item-text": "name",
                                        "item-value": "id",
                                        dense: true,
                                        height: 32,
                                        clearable: true,
                                        "clear-icon": "$clear",
                                        "hide-details": "auto",
                                        "no-data-text": "暂无数据"
                                      },
                                      model: {
                                        value: scope.row.isFourSix,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "isFourSix", $$v)
                                        },
                                        expression: "scope.row.isFourSix"
                                      }
                                    })
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.isFourSix == 1 ? "是" : "否"
                                        )
                                      )
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "promotionForm",
                          label: "促单形式",
                          width: "200",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm.edit &&
                                (_vm.saleSourceName == "智慧校区web" ||
                                  _vm.saleSourceName == "智慧校区h5")
                                  ? _c("v-autocomplete", {
                                      staticStyle: { padding: "6px 0" },
                                      attrs: {
                                        items: [
                                          { id: "招生协助", name: "招生协助" },
                                          {
                                            id: "合伙人自主",
                                            name: "合伙人自主"
                                          }
                                        ],
                                        "menu-props": {
                                          bottom: true,
                                          offsetY: true
                                        },
                                        label: "促单形式",
                                        outlined: "",
                                        "item-text": "name",
                                        "item-value": "id",
                                        dense: true,
                                        height: 32,
                                        clearable: true,
                                        "clear-icon": "$clear",
                                        "hide-details": "auto",
                                        "no-data-text": "暂无数据"
                                      },
                                      model: {
                                        value: scope.row.promotionForm,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "promotionForm",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.promotionForm"
                                      }
                                    })
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(scope.row.promotionForm || "--")
                                      )
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "channelsTypeName",
                          label: "渠道名称",
                          width: "200",
                          "label-class-name": "ty-ml8"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.channelsTypeName || "--")
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "saleSourceName",
                          label: "订单渠道",
                          width: "200",
                          "label-class-name": "ty-ml8"
                        }
                      }),
                      !Number(_vm.$route.query.transferSchool)
                        ? _c(
                            "el-table-column",
                            {
                              attrs: {
                                label: "操作",
                                width: "205",
                                "label-class-name": "ty-ml8",
                                fixed: "right"
                              }
                            },
                            [
                              [
                                !_vm.edit
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                          disabled: _vm.isLock
                                        },
                                        on: { click: _vm.openEdit }
                                      },
                                      [_vm._v("编辑")]
                                    )
                                  : _vm._e(),
                                _vm.edit
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                          disabled: _vm.saveButton
                                        },
                                        on: { click: _vm.editSave }
                                      },
                                      [_vm._v("保存")]
                                    )
                                  : _vm._e(),
                                _vm.edit
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "small" },
                                        on: { click: _vm.closeEdit }
                                      },
                                      [_vm._v("取消")]
                                    )
                                  : _vm._e()
                              ]
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _vm.tobePaid
            ? _c(
                "div",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c("span", { staticClass: "ty-title2" }, [
                    _vm._v("报名协议")
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "ty-title2",
                      staticStyle: {
                        "padding-left": "22px",
                        "padding-right": "14px"
                      }
                    },
                    [_vm._v(_vm._s(_vm.userData[0].signContractStatus))]
                  ),
                  _vm.userData[0].signContractStatus != "线下签订"
                    ? [
                        _vm.btnChangeHiden
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  plain: "",
                                  disabled: _vm.btnChangeEnable,
                                  size: "mini"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.signDetail()
                                  }
                                }
                              },
                              [_vm._v(" 查看协议 ")]
                            )
                          : _vm._e(),
                        _vm.isSchoolEq
                          ? [
                              _vm.orderStatus != "订单关闭" &&
                              _vm.userData[0].signContractStatus === "待审核"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.showPassDialog()
                                        }
                                      }
                                    },
                                    [_vm._v(" 通过 ")]
                                  )
                                : _vm._e(),
                              _vm.orderStatus != "订单关闭" &&
                              (_vm.userData[0].signContractStatus == "待审核" ||
                                _vm.userData[0].signContractStatus == "通过")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "warning", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.reject()
                                        }
                                      }
                                    },
                                    [_vm._v(" 驳回 ")]
                                  )
                                : _vm._e(),
                              _vm.userData[0].signContractStatus !== "免签"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.checkRecord()
                                        }
                                      }
                                    },
                                    [_vm._v(" 审核记录 ")]
                                  )
                                : _vm._e(),
                              _vm.btnChangeEnable
                                ? _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "light",
                                        content: "学员签订协议后可下载协议",
                                        placement: "bottom-end"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "inline",
                                            "margin-left": "12px"
                                          }
                                        },
                                        [
                                          _vm.btnChangeHiden
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    plain: "",
                                                    disabled:
                                                      _vm.btnChangeEnable,
                                                    size: "mini"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.downloadSignDetail()
                                                    }
                                                  }
                                                },
                                                [_vm._v(" 下载协议 ")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.btnChangeHiden && !_vm.btnChangeEnable
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        plain: "",
                                        disabled: _vm.btnChangeEnable,
                                        size: "mini"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.downloadSignDetail()
                                        }
                                      }
                                    },
                                    [_vm._v(" 下载协议 ")]
                                  )
                                : _vm._e()
                            ]
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "line" }),
        _c("div", { staticClass: "ty-page-elform-section pb36" }, [
          _c(
            "div",
            {
              staticClass: "ty-info_title",
              staticStyle: { "margin-bottom": "12px" }
            },
            [_vm._v("商品信息")]
          ),
          _c("div", [
            _c("div", { staticClass: "Mark" }, [
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.goodsData,
                        border: "",
                        stripe: "",
                        size: "mini",
                        "element-loading-text": "拼命加载中",
                        "header-cell-style": { background: "#e5f2ff" }
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          fixed: "",
                          prop: "goodsId",
                          label: "商品ID",
                          width: "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          fixed: "",
                          prop: "goodsName",
                          label: "商品名",
                          "min-width": "190"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "commodityName",
                          label: "班型",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "productName",
                          label: "产品线",
                          "min-width": "140"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "goodsCategoryName",
                          label: "商品分类",
                          "min-width": "190"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          formatter: _vm.isEmptyFun,
                          prop: "goodsSeriesName",
                          label: "产品系列",
                          "min-width": "200"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "goodsTypeName",
                          label: "商品类型",
                          "min-width": "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "pattern",
                          label: "上课形式",
                          "min-width": "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          fixed: "right",
                          width: "180",
                          label: "操作"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.goodsDetail()
                                      }
                                    }
                                  },
                                  [_vm._v(" 商品详情 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.serviceDetail2(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v(" 服务详情 ")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "line" }),
        _c("div", { staticClass: "ty-page-elform-section pb36" }, [
          _c(
            "div",
            {
              staticClass: "ty-info_title",
              staticStyle: { "margin-bottom": "12px" }
            },
            [_vm._v("收费信息")]
          ),
          _c(
            "div",
            { staticClass: "Mark" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.paymentData,
                    border: "",
                    stripe: "",
                    size: "mini",
                    "element-loading-text": "拼命加载中",
                    "header-cell-style": { background: "#e5f2ff" }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      formatter: _vm.numberFormatter,
                      prop: "commodityPrice",
                      label: "商品价格",
                      "min-width": "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      formatter: _vm.numberFormatter,
                      prop: "preferential",
                      label: "优惠额",
                      "min-width": "100"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.preferential +
                                    (scope.row.upClassDiscount || 0)
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm.ios == 1
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          formatter: _vm.numberFormatter,
                          prop: "amountReceivable",
                          "min-width": "120",
                          label: "应收学币"
                        }
                      })
                    : _vm._e(),
                  _vm.ios == 1
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          formatter: _vm.numberFormatter,
                          prop: "paymentAmount",
                          label: "支付学币",
                          "min-width": "120"
                        }
                      })
                    : _vm._e(),
                  _vm.ios == 0
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          formatter: _vm.numberFormatter,
                          prop: "amountReceivable",
                          "min-width": "120",
                          label: "应收金额"
                        }
                      })
                    : _vm._e(),
                  _vm.ios == 0
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          formatter: _vm.numberFormatter,
                          prop: "changeClassDiscount",
                          label: "转班抵扣额",
                          "min-width": "100"
                        }
                      })
                    : _vm._e(),
                  _vm.ios == 0
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          formatter: _vm.numberFormatter,
                          prop: "deduction",
                          label: "余额抵扣额",
                          "min-width": "100"
                        }
                      })
                    : _vm._e(),
                  _vm.ios == 0
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          formatter: _vm.numberFormatter,
                          prop: "paymentAmount",
                          label: "支付金额",
                          "min-width": "120"
                        }
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      formatter: _vm.formatFormAllData,
                      prop: "payMode",
                      label: "支付方式",
                      "min-width": "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      formatter: _vm.formatFormAllData,
                      prop: "state",
                      label: "支付状态",
                      "min-width": "120"
                    }
                  }),
                  _vm.ios == 0
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          formatter: _vm.numberFormatter,
                          prop: "amountReceivedAll",
                          "min-width": "120",
                          label: "已收金额"
                        }
                      })
                    : _vm._e(),
                  _vm.ios == 0
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          formatter: _vm.numberFormatter,
                          prop: "amountReceived",
                          "min-width": "120",
                          label: "已确认收款"
                        }
                      })
                    : _vm._e(),
                  _vm.ios == 0
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          formatter: _vm.numberFormatter,
                          prop: "collectionAmount",
                          "min-width": "120",
                          label: "待收金额"
                        }
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      formatter: _vm.formatFormAllData,
                      prop: "allPay",
                      label: "收齐款",
                      "min-width": "100"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "completionTime",
                      label: "支付时间",
                      width: "140"
                    }
                  }),
                  _vm.ios == 0
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          fixed: "right",
                          label: "操作",
                          width: _vm.loanMethod ? 180 : 100
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return _vm.ios !== 1
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text", size: "mini" },
                                          on: {
                                            click: function($event) {
                                              return _vm.paymentDetail()
                                            }
                                          }
                                        },
                                        [_vm._v(" 收款明细 ")]
                                      ),
                                      _vm.loanMethod
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "mini"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.repaymentProject(
                                                    scope.row
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" 还款明细 ")]
                                          )
                                        : _vm._e()
                                    ]
                                  : undefined
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "line" }),
        _c("div", { staticClass: "ty-page-elform-section pb36" }, [
          _vm.inoutProjectData.length
            ? _c(
                "div",
                {
                  staticClass: "ty-info_title",
                  staticStyle: {
                    "padding-right": "25px",
                    "margin-bottom": "12px"
                  }
                },
                [
                  _vm._v(" 收费项目 "),
                  this.god === "1"
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { float: "right" },
                          attrs: { type: "success" },
                          on: {
                            click: function($event) {
                              return _vm.addInoutFun()
                            }
                          }
                        },
                        [_vm._v("补充收费项目 ")]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "Mark" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.inoutProjectData,
                    border: "",
                    stripe: "",
                    size: "mini",
                    "show-summary": "",
                    "summary-method": _vm.getTotal,
                    "element-loading-text": "拼命加载中",
                    "header-cell-style": { background: "#e5f2ff" }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "inoutProjectName",
                      label: "收支项目",
                      "min-width": "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "standardPrice",
                      label: "标准价格",
                      "min-width": "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "goodsDiscount",
                      label: "优惠额",
                      "min-width": "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.goodsDiscount) + " ")
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "receivable",
                      label: "应收金额",
                      "min-width": "150"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "line" }),
        _c("div", {
          staticClass: "ty-page-form-btn",
          staticStyle: { "text-align": "center", "padding-bottom": "34px" }
        })
      ]),
      _c("GoodsDetailDrawer", {
        ref: "goodsDrawer",
        attrs: {
          orderId: _vm.orderId,
          goodsId: _vm.goodsId,
          visible: _vm.showGoodDetailVisible
        },
        on: {
          "update:visible": function($event) {
            _vm.showGoodDetailVisible = $event
          }
        }
      }),
      _c("service-detail-modal", {
        ref: "serviceDrawer",
        attrs: {
          orderId: _vm.orderId,
          businessId: _vm.businessId,
          visible: _vm.showServiceDetail
        },
        on: {
          "update:visible": function($event) {
            _vm.showServiceDetail = $event
          }
        }
      }),
      _c("service-detail-single-modal", {
        attrs: {
          orderId: _vm.orderId,
          businessId: _vm.businessId,
          visible: _vm.showServiceDetailSingle,
          isHasEffectiveDay: _vm.isHasEffectiveDay
        },
        on: {
          "update:visible": function($event) {
            _vm.showServiceDetailSingle = $event
          }
        }
      }),
      _vm.orderId
        ? _c("CollectDetailModal", {
            ref: "showDetail",
            attrs: {
              payTypeList: _vm.payTypeList,
              orderId: _vm.orderId,
              visible: _vm.showCollectionDetailVisible
            },
            on: {
              "update:visible": function($event) {
                _vm.showCollectionDetailVisible = $event
              }
            }
          })
        : _vm._e(),
      _c("CollectDetailSingleModal", {
        ref: "drawer",
        attrs: {
          payTypeList: _vm.payTypeList,
          orderId: _vm.orderId,
          visible: _vm.showCollectionDetailSingleVisible
        },
        on: {
          "update:visible": function($event) {
            _vm.showCollectionDetailSingleVisible = $event
          }
        }
      }),
      _c("AgreementDetaiModal", {
        ref: "agree",
        attrs: {
          orderId: _vm.orderId,
          name: _vm.name,
          visible: _vm.showSignDetailVisible
        },
        on: {
          "update:visible": function($event) {
            _vm.showSignDetailVisible = $event
          }
        }
      }),
      _c("HistoryAuditRecordModal", {
        ref: "historyDrawer",
        attrs: {
          contractRecordId: _vm.contractRecord.id,
          openType: "3",
          visible: _vm.showHistoryAuditRecordVisible
        },
        on: {
          "update:visible": function($event) {
            _vm.showHistoryAuditRecordVisible = $event
          }
        }
      }),
      _c("RepaymentDetailModal", {
        attrs: {
          orderId: _vm.orderId,
          visible: _vm.showRepaymentDetailVisible
        },
        on: {
          "update:visible": function($event) {
            _vm.showRepaymentDetailVisible = $event
          }
        }
      }),
      _c("AuditReject", {
        attrs: { visible: _vm.showRejectModalVisible },
        on: {
          "update:visible": function($event) {
            _vm.showRejectModalVisible = $event
          },
          rejectOk: _vm.rejectOk
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }